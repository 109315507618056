import React from 'react';
import CanopyTabBar from 'canopy-ui/CanopyTabBar';
import Container from 'react-bootstrap/Container';
import UserManagement from './user-management';

const entries = [{
    title: 'USER MANAGEMENT',
    component: <UserManagement />
}, {
    title: 'OVERVIEW',
    component: <div>Overview</div>
}, {
    title: 'USER ENGAGEMENT',
    component: <div>User Engagement</div>
}];

export default () => {
    return (
        <>
            <Container className="mb-5 mt-5">
                <CanopyTabBar entries={entries} />
            </Container>
        </>
    )
}
