import React from 'react';

type Auth0User = {
    email: string
    email_verified: boolean
    name: string
    nickname: string
    picture: string
    sub: string
    updated_at: string
}

type User = {
    id: number
    email: string
    contractIds: number[] | []
    licenseIds: number[] | []
    institutionId: number
}

/** Intersection Type */
export type Admin = Auth0User


export const defaultAdmin: Admin = {
    email: '',
    email_verified: false,
    name: '',
    nickname: '',
    picture: '',
    sub: '',
    updated_at: ''
};

type AppContext = {
    admin: Admin
};

export const AppContext = React.createContext<AppContext>({ admin: defaultAdmin });


