import React, { useEffect } from 'react';
import CanopyNavBar from 'canopy-ui/CanopyNavBar';
import Credential from './credential';
import { Router, Switch, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AppContext } from './context';
import history from "../utils/history";
import axios from 'utils/axiosUtil';

const {
    REACT_APP_AUDIENCE,
} = process.env;

const App: React.FC<{}> = () => {
    const {
        isLoading,
        isAuthenticated,
        user,
        loginWithRedirect,
        getAccessTokenSilently
    } = useAuth0();

    useEffect(() => {
        (async function login() {
            if (!isLoading && !user) {
                await loginWithRedirect()
            }
        })()
    }, [isLoading, loginWithRedirect, user])

    useEffect(() => {
        (async function getAccessToken() {
            try {
                const accessToken = await getAccessTokenSilently({
                    audience: REACT_APP_AUDIENCE,
                })
                axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
            } catch (e) {
                console.log(e.message)
            }
        }
        )()
    }, [getAccessTokenSilently]);

    if (isAuthenticated) {
        return (
            <>
                <CanopyNavBar
                    link="/"
                    image={require('./_assets/canopy.png')}
                    user={user}
                />
                <AppContext.Provider value={{ admin: user }}>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/">
                                <Credential />
                            </Route>
                        </Switch>
                    </Router>
                </AppContext.Provider>
            </>
        )
    }

    return (
        <>
        </>
    )
};

export default App;
