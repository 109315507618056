import React, { useState } from 'react';
import { useQuery, useMutation, queryCache } from 'react-query';
import { CSVLink } from "react-csv";
import ReactLoading from 'react-loading';
import Alert from 'react-bootstrap/Alert'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import Badge from 'react-bootstrap/Badge';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import getExamsAdminCanAdminister from './_api/get_exams_admin_can_administer';
import adminProvisionUsers from './_api/admin_provision_users';
import { Props, NewUser } from './types';

const InviteUsers = (props: Props) => {
  /** 
   * React useContext and useState
   */
  const [newUsers, setNewUsers] = useState<NewUser[]>([]);
  const [fileName, setFileName] = useState<string>('Choose a CSV file');
  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [product_id, setProductId] = useState<number>(0);
  const [contract_id, setContractId] = useState<number>(0);
  // const [licenseCount, setLicenseCount] = useState(100);

  /**
   * ReactQuery hooks
   */
  const { isLoading, error, data } = useQuery('getExams', () => getExamsAdminCanAdminister());

  /**
   * Function to provision users. This will also invalidate the getUsersByInstitutionId query
   */
  const [mutate, mutation] = useMutation(adminProvisionUsers, {
    onSuccess: (e) => {
      queryCache.invalidateQueries('getUsersByInstitutionId')
      setSuccessMessage(true)
    },
    onError: (e) => {
      setErrorMessage(true)
    }
  });

  /**
   * Function to process uploaded CSV
   * @param e 
   */
  const csvProcessor = (e: any) => {
    const input = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(input);
    reader.onload = (e) => {
      const csv = e?.target?.result as string;
      const res: NewUser[] = csv.split(/\r\n|\n/).slice(1, csv.length - 1).map(entry => entry.split(",")).map(e => {
        return {
          "email": e[csv.toLowerCase().split(/\r\n|\n/)[0].split(',').indexOf('email')].replace(/"/g, '').trim(),
          "first_name": e[csv.toLowerCase().split(/\r\n|\n/)[0].split(',').indexOf('firstname')].replace(/"/g, '').trim(),
          "last_name": e[csv.toLowerCase().split(/\r\n|\n/)[0].split(',').indexOf('lastname')].replace(/"/g, '').trim(),
        }
      });
      setNewUsers(res)
      setFileName(input.name)
    }
    reader.onerror = (e) => {
      console.log(e);
    }
  };

  /**
   * adminProvisionUsers params
   */
  const adminProvisionUsersParams = {
    users: newUsers,
    contract_id: contract_id,
    product_id: product_id,
  };

  if (isLoading) {
    return (
      <div>
        Loading...
      </div>
    )
  }

  if (error) {
    return (
      <div>
        Dang...
      </div>
    )
  }

  if (data) {
    return (
      <>
        <Alert
          show={successMessage}
          variant="success"
          onClose={() => setSuccessMessage(false)} dismissible
          children="User creation is complete!"
        />
        <Alert
          show={errorMessage}
          variant="danger"
          onClose={() => setErrorMessage(false)} dismissible
          children="Something went wrong. Try again"
        />
        <Alert
          show={mutation.isLoading}
          variant="info"
          children={<div className="d-flex flex-row align-items-center">User creation in progress <ReactLoading type={"balls"} color="#fff" /></div>}
        />
        <Breadcrumb>
          <Breadcrumb.Item onClick={props.backLink}>
            &lt; BACK
                    </Breadcrumb.Item>
        </Breadcrumb>
        <Card className="">
          <Card.Header>USERS TO INVITE</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group>
                <Form.Label>Upload CSV</Form.Label>
                <Form.File
                  id="custom-file"
                  label={fileName}
                  custom
                  onChange={(e: any) => csvProcessor(e)}
                  accept=".csv"
                />
              </Form.Group>
              <fieldset>
                <Form.Group>
                  <Form.Label>Select Exam</Form.Label>
                  {
                    data.data.map((entry: any, idx: number) => (
                      <Form.Check
                        key={entry.id}
                        type="radio"
                        label="Qualified Bilingual Staff (QBS) - English + Spanish"
                        name="formelements"
                        id={entry.product_id}
                        onChange={() => {
                          setProductId(entry.product_id);
                          setContractId(entry.contract_id)
                        }
                        }
                      />
                    ))
                  }
                </Form.Group>
              </fieldset>
            </Form>
            {/* <Button variant="primary" className="mb-3">
              Licenses Remaining <Badge variant="light">{licenseCount}</Badge>
            </Button> */}
            <hr />
            <Button
              disabled={mutation.isLoading}
              variant="secondary"
              onClick={() => mutate(adminProvisionUsersParams)}
              children="SEND INVITES"
            />
            {' '}
            <CSVLink
              data={""}
              enclosingCharacter={`'`}
              filename={"bulk-user-create.csv"}
              className="btn btn-primary"
              headers={['email', 'firstName', 'lastName']}
            >
              DOWNLOAD CSV UPLOAD TEMPLATE
            </CSVLink>
            {' '}
            <Button
              variant="light"
              onClick={props.backLink}
              children="CANCEL"
            />
          </Card.Body>
        </Card>
        <br />
        { mutation.isSuccess &&
          <>
            <Card>
              <Card.Header>USER INVITE FAILURES</Card.Header>
              <Container className="bg-white mt-5 p-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th> Email </th>
                      <th> First Name </th>
                      <th> Last Name </th>
                      <th> Reason </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      mutation.data.data[1].map((entry: any, idx: number) => (
                        <tr key={idx}>
                          <td>{entry.email ? entry.email : 'Unknown'}</td>
                          <td>{entry.first_name ? entry.first_name : 'Unknown'}</td>
                          <td>{entry.last_name ? entry.last_name : 'Unknown'}</td>
                          <td>{entry.reason ? entry.reason : 'Unknown'}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                <hr />
                <CSVLink
                  data={mutation.data.data[1]}
                  enclosingCharacter=""
                  filename={"error-users.csv"}
                  className="btn btn-primary"
                  headers={[{
                    label: "email",
                    key: "email"
                  }, {
                    label: "firstName",
                    key: "first_name"
                  }, {
                    label: "lastName",
                    key: "last_name"
                  }]}
                >
                  DOWNLOAD INVITE FAILURES CSV
                </CSVLink>
              </Container>
            </Card>
            <br />
            <Card>
              <Card.Header>USER INVITE SUCCESSES</Card.Header>
              <Container className="bg-white mt-5 p-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th> Email </th>
                      <th> First Name </th>
                      <th> Last Name </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      mutation.data.data[0].map((entry: any, idx: number) => (
                        <tr key={idx}>
                          <td>{entry.email ? entry.email : 'Unknown'}</td>
                          <td>{entry.first_name ? entry.first_name : 'Unknown'}</td>
                          <td>{entry.last_name ? entry.last_name : 'Unknown'}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Container>
            </Card>
          </>
        }
      </>
    )
  }

  return (
    <>
      Something went wrong
    </>
  )
};

export default InviteUsers
