import axios from 'utils/axiosUtil';

export default async (): Promise<any> => {
    try {
        const res = await axios.get(`/api/canopy_licenses/get_admin_licenses`)
        return res.data
    } catch (error) {
        console.log(error)
    }
}
