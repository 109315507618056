import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useAuth0 } from '@auth0/auth0-react';
import './styles.scss';

type CanopyNavBarProps = {
    link: string,
    image: string,
    user?: any,
    children?: React.ReactNode,
};

const CanopyNavBar: React.FunctionComponent<CanopyNavBarProps> = ({ link, image, children, user }) => {
    const { isAuthenticated, logout, loginWithRedirect } = useAuth0();
    return (
        <Navbar bg="primary" className="shadow">
            <Navbar.Brand href={link} className="mr-auto">
                <img src={image} alt="Canopy Logo" height="30" width="110" />
            </Navbar.Brand>
            <NavDropdown title={user?.email ?? 'welcome'} id="basic-nav-dropdown">
                <NavDropdown.Item href="https://share.hsforms.com/1559vlmxjRfCWYGzpkgwP_A3jlta">
                    Support
                </NavDropdown.Item>
                {
                    isAuthenticated && (
                        <NavDropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>
                            Logout
                        </NavDropdown.Item>
                    )
                }
                {
                    !isAuthenticated && (
                        <NavDropdown.Item onClick={() => loginWithRedirect()}>
                            Login
                        </NavDropdown.Item>
                    )
                }
            </NavDropdown>
        </Navbar >
    )
};

export default CanopyNavBar;
