import axios from 'utils/axiosUtil';
import { CanopyResponse } from 'types';

export default async (): Promise<CanopyResponse> => {
    try {
        const res = await axios.get('/api/users/institution/users')
        return res.data
    } catch (error) {
        throw new Error(error)
    }
};
