import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import getUsersByInstitutionId from './_api/get_users_by_institution_id';
import InviteUsers from './invite_users';

const UserManagement = () => {

    // React Hooks
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);

    const {
        isLoading,
        error,
        data
    } = useQuery('getUsers', () => getUsersByInstitutionId());

    if (isLoading) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    if (error) {
        return (
            <div>
                Dang...
            </div>
        )
    }

    if (data) {
        switch (page) {
            case 0:
                return (
                    <>
                        <Container className="mt-3 d-flex flex-row-reverse">
                            <Button variant="secondary" onClick={() => setPage(1)}>
                                Invite Users
                            </Button>
                        </Container>

                        <Container className="bg-white mt-5 p-3">
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="filter">
                                        <Form.Label>SEARCH FILTER</Form.Label>
                                        <Form.Control type="text" placeholder="Email" onChange={(e) => setFilter(e.target.value)} />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="filter">
                                        <Form.Label>STATUS</Form.Label>
                                        <Form.Control as="select" defaultValue="All Status">
                                            <option>All Status</option>
                                            <option>Activated</option>
                                            <option>Deactivated</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </Container>

                        <Container className="bg-white mt-5 p-3">
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th> First Name </th>
                                        <th> Last Name </th>
                                        <th> Email </th>
                                        <th> Status </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.data.filter((entry: any) => entry.email.includes(filter)).map((entry: any, idx: number) => (
                                            <tr key={idx}>
                                                <td>{entry.first_name ? entry.first_name : 'Unknown'}</td>
                                                <td>{entry.last_name ? entry.last_name : 'Unknown'}</td>
                                                <td>{entry.email}</td>
                                                <td>Activated</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </Container>
                    </>
                )
            case 1:
                return <InviteUsers backLink={() => setPage(0)} />
            default:
                return <div>Hey</div>
        }
    }

    return (
        <div>
            Default
        </div>
    )
}

export default UserManagement
