import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';

const {
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUDIENCE,
} = process.env;

ReactDOM.render(
    <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN as string}
        clientId={REACT_APP_AUTH0_CLIENT_ID as string}
        redirectUri={window.location.origin}
        audience={REACT_APP_AUDIENCE as string}
    >
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
