import { NewUser } from '../../types';
import axios from 'utils/axiosUtil';

type Props = {
  users: NewUser[]
  contract_id: number,
  product_id: number,
};


export default async ({ users, contract_id, product_id }: Props): Promise<any> => {
  try {
    const new_users = users.map((user: any) => ({
      first_name: user.first_name,
      last_name: user.last_name,
      password: 'Canopy123',
      user_type: 'B2B.User',
      email: user.email,
      contract_id: contract_id,
      product_id: product_id,
      role_id: 1,
    }));
    const res = await axios.post('/api/users/create_all_bulk', { users: { users: new_users } })
    return res.data
  } catch (error) {
    console.log(error)
    throw new Error('could not create users')
  }
}
