import React from 'react';
import TabContainer from 'react-bootstrap/TabContainer';
import TabContent from 'react-bootstrap/TabContent';
import Tab from 'react-bootstrap/Tab';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

type titleComponent = {
    title: string,
    component: React.ReactNode,
}

type CanopyTabBarProps = {
    entries: titleComponent[],
};

const CanopyTabBar: React.FunctionComponent<CanopyTabBarProps> = ({ entries }) => (
    <Container>
        <TabContainer defaultActiveKey={0}>
            <Nav fill variant="pills" style={{ backgroundColor: '#bfbfbf' }}>
                {
                    entries.map((entry, idx: number) => (
                        <Nav.Item key={idx}>
                            <Nav.Link eventKey={idx} style={{ color: 'white', fontWeight: 200 }}>{entry.title}</Nav.Link>
                        </Nav.Item>
                    ))
                }
            </Nav>
            <Row>
                <Col sm={12}>
                    <TabContent>
                        {
                            entries.map((entry, idx: number) => (
                                <Tab.Pane eventKey={idx} key={idx}>
                                    {entry.component}
                                </Tab.Pane>
                            ))
                        }
                    </TabContent>
                </Col>
            </Row>
        </TabContainer>
    </Container>
);

export default CanopyTabBar;
